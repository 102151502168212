import { Login } from 'react-admin';

//@ts-ignore
import Logo from '../assets/logo-r-w.jpg';

export const LoginPage = () => (
  <Login
    backgroundImage={Logo}
    sx={{
      backgroundColor: '#fff',
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat',
      backgroundPosition: 'center',
      placeContent: 'flex-start'
    }}
  />
);
