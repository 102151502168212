import { CSSProperties } from "@mui/material/styles/createMixins";

export const elipsisStyles = {
  maxWidth: 250,
  display: "block",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
} as CSSProperties;

export const gridColumnStyles = {
  gap: ["0px", "0px", "5px"],
  width: "100%",
  display: "grid",
  gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
};

export const currencyOptions = {
  style: "currency",
  currency: "PLN",
  locales: "pl-PL",
  minimumFractionDigits: 2,
};

export const placeholderField = "NIE DOTYCZY" || "Nie dotyczy";

export const filterListElipsis = {
  "& .MuiTypography-root ": {
    textOverflow: "ellipsis",
    maxWidth: "150px",
    whiteSpace: "nowrap",
    display: "block !important",
    overflow: "hidden",
  },
};
