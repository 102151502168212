import { Grid } from "@mui/material";
import {
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
} from "react-admin";

import { gridColumnStyles } from "../../../utils";

export const VariantForm = () => {
  return (
    <>
      <TextInput label="nazwa wariantu" source="name" validate={[required()]} />

      <ReferenceInput
        source="modelId"
        reference="models"
        label="model"
        validate={[required()]}
      >
        <SelectInput validate={[required()]} />
      </ReferenceInput>

      <ArrayInput source="includes" label="zawartość">
        <SimpleFormIterator>
          <ReferenceInput
            source="tool"
            reference="tools"
            validate={[required()]}
          >
            <Grid sx={gridColumnStyles}>
              <SelectInput
                source="modelId"
                validate={[required()]}
                label="narzędzie"
              />
              <NumberInput
                source="quantity"
                label="ilość"
                defaultValue={1}
                validate={[required()]}
              />
            </Grid>
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
