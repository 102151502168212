import { useState } from 'react';
import { LoadingContext } from './components/global/context';

import { Admin, Resource } from 'react-admin';
import { authProvider, dataProvider } from './configs/firebase/config';

import { ToolList } from './components/lists/ToolList';
import { EditTool } from './components/actions/tools/forms/EditTool';
import { CreateTool } from './components/actions/tools/forms/CreateTool';

import { CategoryList } from './components/lists/CategoryList';
import { EditCategory } from './components/actions/categories/forms/EditCategory';
import { CreateCategory } from './components/actions/categories/forms/CreateCategory';
import { EditSubcategory } from './components/actions/subcategories/forms/EditSubcategory';
import { CreateSubcategory } from './components/actions/subcategories/forms/CreateSubcategory';

import { MakeList } from './components/lists/MakeList';
import { EditMake } from './components/actions/makes/forms/EditMake';
import { CreateMake } from './components/actions/makes/forms/CreateMake';

import { ModelList } from './components/lists/ModelList.';
import { EditModel } from './components/actions/models/forms/EditModel';
import { CreateModel } from './components/actions/models/forms/CreateModel';

import { VariantList } from './components/lists/VariantList';
import { EditVariant } from './components/actions/variants/forms/EditVariant';
import { CreateVariant } from './components/actions/variants/forms/CreateVariant';

import { SystemList } from './components/lists/SystemList';
import { EditSystem } from './components/actions/systems/forms/EditSystem';
import { CreateSystem } from './components/actions/systems/forms/CreateSystem';

import { TechnologyList } from './components/lists/TechnologyList';
import { EditTechnology } from './components/actions/technologies/forms/EditTechnology';
import { CreateTechnology } from './components/actions/technologies/forms/CreateTechnology';

import { IndustryList } from './components/lists/IndustryList';
import { EditIndustry } from './components/actions/industries/forms/EditIndustry';
import { CreateIndustry } from './components/actions/industries/forms/CreateIndustry';

import { UserList } from './components/lists/UserList';
import { EditUser } from './components/actions/users/forms/EditUser';
import { CreateUser } from './components/actions/users/forms/CreateUser';

import { TodoList } from './components/lists/TodoList';
import { EditTodo } from './components/actions/todos/forms/EditTodo';
import { CreateTodo } from './components/actions/todos/forms/CreateTodo';

import { IncomeList } from './components/lists/IncomeList';
import { EditIncome } from './components/actions/incomes/forms/EditIncome';
import { CreateIncome } from './components/actions/incomes/forms/CreateIncome';

import { OutcomeList } from './components/lists/OutcomeList';
import { EditOutcome } from './components/actions/outcomes/forms/EditOutcome';
import { CreateOutcome } from './components/actions/outcomes/forms/CreateOutcome';

import { InvoiceList } from './components/lists/InvoiceList';
import { EditInvoice } from './components/actions/invoices/forms/EditInvoice';
import { CreateInvoice } from './components/actions/invoices/forms/CreateInvoice';

import { LoginPage } from './pages/LoginPage';
import { CustomLayout } from './components/global/CustomLayout';

// TODO: add filters to lists,
// TODO: add product details,
// TODO: add product code fetcher

export const App = () => {
  const [isRootLoading, setIsRootLoading] = useState(true);
  return (
    <LoadingContext.Provider
      value={{
        isRootLoading,
        setIsRootLoading,
      }}
    >
      <Admin
        layout={CustomLayout}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
      >
        <Resource
          hasEdit
          hasCreate
          name='tools'
          list={ToolList}
          edit={EditTool}
          create={CreateTool}
          recordRepresentation='displayName'
          options={{ label: 'Narzędzia' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='categories'
          list={CategoryList}
          edit={EditCategory}
          create={CreateCategory}
          recordRepresentation='name'
          options={{ label: 'Kategorie' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='subcategories'
          list={<CategoryList isSubCategory />}
          edit={EditSubcategory}
          create={CreateSubcategory}
          recordRepresentation='name'
          options={{ label: 'Subkategorie' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='makes'
          list={MakeList}
          edit={EditMake}
          create={CreateMake}
          recordRepresentation='name'
          options={{ label: 'Producenci' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='models'
          list={ModelList}
          edit={EditModel}
          create={CreateModel}
          recordRepresentation='name'
          options={{ label: 'Modele' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='variants'
          list={VariantList}
          create={CreateVariant}
          edit={EditVariant}
          recordRepresentation='name'
          options={{ label: 'Warianty' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='systems'
          list={SystemList}
          edit={EditSystem}
          create={CreateSystem}
          recordRepresentation='name'
          options={{ label: 'Systemy' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='technologies'
          list={TechnologyList}
          edit={EditTechnology}
          create={CreateTechnology}
          recordRepresentation='name'
          options={{ label: 'Technologie' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='industries'
          list={IndustryList}
          edit={EditIndustry}
          create={CreateIndustry}
          recordRepresentation='name'
          options={{ label: 'Branże' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='users'
          list={UserList}
          edit={EditUser}
          create={CreateUser}
          recordRepresentation='name'
          options={{ label: 'Użytkownicy' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='incomes'
          list={IncomeList}
          edit={EditIncome}
          create={CreateIncome}
          recordRepresentation='displayName'
          options={{ label: 'Wpływy' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='outcomes'
          list={OutcomeList}
          edit={EditOutcome}
          create={CreateOutcome}
          recordRepresentation='name'
          options={{ label: 'Wydatki' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='invoices'
          list={InvoiceList}
          edit={EditInvoice}
          create={CreateInvoice}
          recordRepresentation='name'
          options={{ label: 'Faktury' }}
        />

        <Resource
          hasEdit
          hasCreate
          name='todos'
          list={TodoList}
          edit={EditTodo}
          create={CreateTodo}
          recordRepresentation='name'
          options={{ label: 'TODO' }}
        />

        <Resource name='invoiceTypes' recordRepresentation='name' />
        <Resource name='invoiceStatuses' recordRepresentation='name' />
      </Admin>
    </LoadingContext.Provider>
  );
};
