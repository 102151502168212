import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';
import { InvoiceForm } from '../InvoiceForm';

export const CreateInvoice = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='create'
      title='Dodaj fakturę'
      redirect={props.redirect}
    >
      <InvoiceForm />
    </ActionController>
  );
};
