import { FormEvent, useState } from 'react';
import { useCreateSuggestionContext, useCreate } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';

import { CreateNewDialogPropsType } from '../../types/global.types';

export const CreateNewDialog = (props: CreateNewDialogPropsType) => {
  const { reference, label } = props;

  const [create] = useCreate();
  const [name, setName] = useState('');
  const { onCancel, onCreate } = useCreateSuggestionContext();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      reference,
      { data: { name } },
      {
        onSuccess: (data) => {
          setName('');
          onCreate(data);
        }
      }
    );
  };

  return (
    <Dialog
      open
      onClose={onCancel}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label={label}
            value={name}
            onChange={({ target: { value } }) => setName(value)}
            autoFocus
            required
          />
        </DialogContent>
        <DialogActions>
          <Button type='submit'>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
