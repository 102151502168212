import { DocumentData } from 'firebase/firestore';

import { ToolsFormValues } from '../../../../types/tools.types';
import { fetchData } from '../../../../apiCalls/fetchData';

const handleCollectionName = (key: string) => {
  switch (key) {
    case 'make':
    case 'makeId':
      return 'makes';
    case 'model':
    case 'modelId':
      return 'models';
    case 'system':
    case 'systemId':
      return 'systems';
    default:
      return '';
  }
};

const handleCreateCombinedDisplayName = async (data: ToolsFormValues) => {
  const filteredData = {
    system: data.systemId,
    model: data.modelId,
  };

  type FilteredDataType = keyof typeof filteredData;
  const dataKeys = Object.keys(filteredData);

  const collectionsPromiseArray: (
    | Promise<DocumentData | undefined>
    | undefined
  )[] = dataKeys.map((key) => {
    const keyTyped = key as FilteredDataType;
    const collectionName = handleCollectionName(keyTyped);
    const id = filteredData[keyTyped];

    const promise = fetchData(collectionName, id);
    return promise;
  });

  const result = await Promise.all(collectionsPromiseArray);
  const filteredResult = result.filter(
    (item) => item?.name.toLowerCase() !== 'nie dotyczy'
  );
  const resultValue = filteredResult.map((item) => item?.name).join(' - ');

  return resultValue;
};

export const submitHandler = async (
  values: ToolsFormValues,
  callback: (data: ToolsFormValues) => void
) => {
  const displayName = await handleCreateCombinedDisplayName(values);
  const data = {
    ...values,
    displayName: `${values.name} - ${displayName}`,
  };

  callback(data);
};

export const getPictureUrlByTerm = async (searchTerm: string) => {
  const apiUrl = `http://localhost:8080/https://services.milwaukeetool.eu/ajax/findTools?term=${searchTerm}`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const pictureUrl = data[0].picture;

    if (!pictureUrl) {
      return '';
    }

    return `http://webservice.ttigroup.eu/local/uploaded/catalog/${pictureUrl}`;
  } catch (error) {
    console.error('Error fetching details data:', error);
  }
};
