import { TechnologyForm } from '../TechnologyForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const CreateTechnology = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='create'
      title='Dodaj technologię'
      redirect={props.redirect}
    >
      <TechnologyForm />
    </ActionController>
  );
};
