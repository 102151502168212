import { ModelForm } from '../ModelForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const EditModel = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='edit'
      title='Edytuj model'
      redirect={props.redirect}
    >
      <ModelForm />
    </ActionController>
  );
};
