import { ActionController } from '../../ActionController';
import { CategoryForm } from '../../categories/CategoryForm';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const EditSubcategory = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='edit'
      title='Edytuj podkategorię'
      redirect={props.redirect}
    >
      <CategoryForm isSubCategory />
    </ActionController>
  );
};
