import { CategoryForm } from '../CategoryForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const CreateCategory = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='create'
      title='Dodaj kategorię'
      redirect={props.redirect}
      grid={false}
    >
      <CategoryForm isSubCategory={props.isSubcategory} />
    </ActionController>
  );
};
