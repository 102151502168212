import { SystemForm } from '../SystemForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const CreateSystem = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='create'
      title='Dodaj system'
      redirect={props.redirect}
    >
      <SystemForm />
    </ActionController>
  );
};
