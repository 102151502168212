import { useGetList, useRedirect, useUpdate } from 'react-admin';

import { ToolForm } from '../ToolForm';
import { ActionController } from '../../ActionController';
import { ToolsFormValues } from '../../../../types/tools.types';
import { ActionControllerPropsType } from '../../../../types/global.types';
import { submitHandler } from '../helpers/utils';
import { IncomesFormValues } from '../../../../types/income.types';

export const EditTool = (props: ActionControllerPropsType) => {
  const [update] = useUpdate();
  const redirect = useRedirect();

  const { data: incomes } = useGetList('incomes', {
    pagination: { page: 1, perPage: 150 },
  });

  const onSubmit = async (values: ToolsFormValues) => {
    const foundIncome = incomes?.filter(
      (income) => income.toolId === values.id
    ) as IncomesFormValues[];

    await submitHandler(values, (data) => {
      update('tools', { id: data.id, data });
      if (foundIncome.length) {
        foundIncome.map((income) => {
          const diff = { displayName: data.displayName };
          update('incomes', {
            id: income.id,
            data: diff,
            previousData: income,
          });
        });
      }
      redirect('list', 'tools');
    });
  };

  return (
    <ActionController
      action='edit'
      title='Edytuj narzędzie'
      redirect={props.redirect}
      onSubmit={onSubmit}
    >
      <ToolForm />
    </ActionController>
  );
};
