import { createContext } from 'react';
import { SetStateBoolean } from '../../../types/global.types';

export interface LoadingContextData {
  isRootLoading: boolean;
  setIsRootLoading: SetStateBoolean;
}

const loadingContextDefaultValue: LoadingContextData = {
  isRootLoading: true,
  setIsRootLoading: () => null
};

export const LoadingContext = createContext<LoadingContextData>(loadingContextDefaultValue);
