import { useCreate, useRedirect } from "react-admin";

import { ToolForm } from "../ToolForm";
import { ActionController } from "../../ActionController";
import { ToolsFormValues } from "../../../../types/tools.types";
import {
  // getPictureUrlByTerm,
  submitHandler,
} from "../helpers/utils";
import { ActionControllerPropsType } from "../../../../types/global.types";

export const CreateTool = (
  props: ActionControllerPropsType<ToolsFormValues>
) => {
  const [create] = useCreate();
  const redirect = useRedirect();

  const onSubmit = async (values: ToolsFormValues) => {
    // const system = document.getElementById('systemId')?.innerText;
    // const model = (document.getElementById('modelId') as HTMLInputElement)?.value;

    // const searchTerm = `${system}${model}`;
    // const pictureUrl = await getPictureUrlByTerm(searchTerm);

    // const formData = {
    //   ...values,
    //   pictureUrl
    // };

    await submitHandler(values, (data) => {
      create("tools", { data });
      redirect("list", "tools");
    });
  };

  return (
    <ActionController
      action="create"
      title="Dodaj narzędzie"
      redirect={props.redirect}
      onSubmit={onSubmit}
    >
      <ToolForm />
    </ActionController>
  );
};
