import {
  DateInput,
  FileField,
  FileInput,
  NumberInput,
  SelectInput,
  TextInput,
  required,
  useGetList,
} from 'react-admin';

export const InvoiceForm = () => {
  const { data: invoiceTypes, isLoading: isLoadingTypes } =
    useGetList('invoiceTypes');
  const { data: invoiceStatuses, isLoading: isLoadingStatuses } =
    useGetList('invoiceStatuses');

  if (isLoadingTypes || isLoadingStatuses) {
    return null;
  }

  return (
    <>
      <TextInput label='nazwa' source='name' validate={required()} />

      <SelectInput
        source='statusNumber'
        label='status'
        validate={required()}
        optionValue='typeNumber'
        choices={invoiceStatuses}
      />

      <SelectInput
        source='invoiceType'
        label='typ'
        validate={required()}
        optionValue='typeNumber'
        choices={invoiceTypes}
      />

      <DateInput source='date' label='data wystawienia' validate={required()} />

      <NumberInput
        label='kwota faktury'
        source='amount'
        validate={required()}
      />

      <FileInput
        source='file'
        label={false}
        accept='application/pdf'
        placeholder='Upuść plik lub kliknij, aby go wybrać'
        validate={required()}
      >
        <FileField source='src' title='title' />
      </FileInput>
    </>
  );
};
