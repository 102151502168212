import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { Theme, withStyles } from "@material-ui/core/styles";
import { Styles } from "@material-ui/core/styles/withStyles";

//@ts-ignore
import Logo from "../../assets/logo-white.png";
import { useMediaQuery } from "@mui/material";

const styles = {
  title: {
    flex: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  spacer: {
    flex: 1,
  },
} as Styles<Theme, {}, string>;

const Pre: React.FC<{ classes: any }> = ({ classes, ...props }) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <AppBar {...props}>
      <Typography
        color="inherit"
        id="react-admin-title"
        className={classes.title}
      />
      {!isSmall && <img src={Logo} style={{ maxHeight: "49px" }} alt="logo" />}
      <span className={classes.spacer} />
    </AppBar>
  );
};

export const CustomAppBar = withStyles(styles)(Pre);
