import { Box } from '@mui/material';
import { ReferenceInput, SelectInput, required, TextInput } from 'react-admin';

export const ModelForm = ({ selectedMakeId }: { selectedMakeId?: string }) => {
  return (
    <>
      <TextInput
        label='nazwa'
        source='name'
        validate={required()}
      />

      <Box sx={{ visibility: selectedMakeId ? 'hidden' : 'initial' }}>
        <ReferenceInput
          source='makeId'
          reference='makes'
        >
          <SelectInput
            source='make'
            label='Powiązany producent'
            validate={required()}
            defaultValue={selectedMakeId}
            fullWidth
          />
        </ReferenceInput>
      </Box>
    </>
  );
};
