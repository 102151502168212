import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Create, Edit, SimpleForm } from 'react-admin';

import { gridColumnStyles } from '../../utils';
import { ActionWrapperPropsType } from '../../types/global.types';

export const ActionController = (props: ActionWrapperPropsType) => {
  const { action = 'create', redirect = 'list', grid = true, title, children, transform, onSubmit, onChange } = props;

  const [Components, setComponents] = useState(<></>);

  useEffect(() => {
    let TmpElem = <></>;

    if (action === 'edit') {
      TmpElem = (
        <Edit
          redirect={redirect}
          title={title}
        >
          <SimpleForm
            // warnWhenUnsavedChanges
            {...(onSubmit && { onSubmit })}
            {...(onChange && { onChange })}
          >
            <Grid
              width='100%'
              sx={{ ...(grid && gridColumnStyles) }}
            >
              {children}
            </Grid>
          </SimpleForm>
        </Edit>
      );
    } else if (action === 'create') {
      TmpElem = (
        <Create
          redirect={redirect}
          title={title}
          transform={transform}
        >
          <SimpleForm
            id={`${title}-form`}
            // warnWhenUnsavedChanges
            {...(onSubmit && { onSubmit })}
            {...(onChange && { onChange })}
          >
            <Grid
              width='100%'
              sx={{ ...(grid && gridColumnStyles) }}
            >
              {children}
            </Grid>
          </SimpleForm>
        </Create>
      );
    } else {
      TmpElem = <>{children}</>;
    }

    setComponents(TmpElem);
  }, [action, children, grid, onChange, onSubmit, redirect, title, transform]);

  return Components;
};
