import { Layout } from 'react-admin';
import { CustomAppBar } from './CustomAppBar';

type CustomLayoutPropsType = {};

export const CustomLayout = (props: CustomLayoutPropsType) => (
  <Layout
    {...props}
    appBar={CustomAppBar}
  />
);
