import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  BulkDeleteButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateField,
  useGetList,
} from 'react-admin';

import { EmptyBox } from '../table/EmptyBox';
import { HeaderLabel } from '../table/HeaderLabel';

export const ToolList = () => {
  const { data: incomes, isLoading } = useGetList('tools', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdate', order: 'DESC' },
  });

  return (
    <List
      empty={<EmptyBox createLabel='Dodaj' hasCreate />}
      filters={[
        <TextInput label='wyszukaj...' source='displayName' alwaysOn />,
        <ReferenceInput source='systemId' reference='systems'>
          <SelectInput name='systemId' label='system' />
        </ReferenceInput>,
        <ReferenceInput label='Model' source='modelId' reference='models'>
          <SelectInput name='modelId' label='model' />
        </ReferenceInput>,
        <ReferenceInput label='Producent' source='makeId' reference='makes'>
          <SelectInput name='makeId' label='producent' />
        </ReferenceInput>,
        <ReferenceInput
          label='Kategoria'
          source='subcategoryId'
          reference='subcategories'
        >
          <SelectInput name='subcategoryId' label='kategoria' />
        </ReferenceInput>,
      ]}
      perPage={50}
      sort={{ field: 'createdate', order: 'DESC' }}
      // aside={<FilterSidebar />}
    >
      <Datagrid
        size='small'
        rowClick='edit'
        sx={{ textTransform: 'uppercase' }}
        bulkActionButtons={<BulkDeleteButton mutationMode='pessimistic' />}
      >
        <TextField source='displayName' label={<HeaderLabel label='nazwa' />} />

        <ReferenceField
          source='modelId'
          reference='models'
          label={<HeaderLabel label='model' />}
        />

        <ReferenceField
          source='systemId'
          reference='systems'
          label={<HeaderLabel label='system' />}
        />

        <ReferenceField
          source='makeId'
          reference='makes'
          label={<HeaderLabel label='producent' />}
        />

        <ReferenceArrayField
          source='industryIds'
          reference='industries'
          label={<HeaderLabel label='branże' />}
        />

        <DateField source='createdate' label={<HeaderLabel label='Dodano' />} />
      </Datagrid>
    </List>
  );
};
