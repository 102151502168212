import { ModelForm } from '../ModelForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const CreateModel = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='create'
      title='Dodaj model'
      redirect={props.redirect}
    >
      <ModelForm selectedMakeId={props.selectedMakeId} />
    </ActionController>
  );
};
