import { useState } from "react";
import {
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteInput,
  useGetList,
} from "react-admin";

import { CreateNewDialog } from "../../dialogs/CreateNewDialog";
import { CreateNewCategoryDialog } from "../../dialogs/CreateNewCategoryDialog";
import { CreateNewModelDialog } from "../../dialogs/CreateNewModelDialog";
import { Box } from "@mui/material";

export const ToolForm = () => {
  const [selectedMakeId, setSelectedMakeId] = useState();
  const { data: models } = useGetList("models");

  return (
    <>
      <TextInput
        label="nazwa"
        source="name"
        name="name"
        fullWidth
        validate={[required()]}
      />

      <Box />

      <ReferenceInput source="subcategoryId" reference="subcategories">
        <SelectInput
          label="kategoria"
          validate={[required()]}
          createLabel="dodaj nowy"
          create={
            <CreateNewCategoryDialog
              label="nazwa kategorii"
              reference="subcategories"
            />
          }
        />
      </ReferenceInput>

      <ReferenceInput source="makeId" reference="makes">
        <SelectInput
          label="producent"
          validate={[required()]}
          source="makeId"
          createLabel="dodaj nowy"
          onChange={(data) => {
            const value = data?.target.value || data;
            setSelectedMakeId(value);
          }}
          create={
            <CreateNewDialog label="nazwa producenta" reference="makes" />
          }
        />
      </ReferenceInput>

      <ReferenceInput source="systemId" reference="systems">
        <SelectInput
          label="system"
          validate={[required()]}
          createLabel="dodaj nowy"
          create={<CreateNewDialog label="nazwa systemu" reference="systems" />}
        />
      </ReferenceInput>

      <ReferenceInput source="modelId" reference="models">
        <AutocompleteInput
          label="model"
          createLabel="Dodaj nowy model"
          source="modelId"
          choices={models}
          validate={[required()]}
          create={
            <CreateNewModelDialog
              reference="models"
              selectedMakeId={selectedMakeId}
            />
          }
        />
      </ReferenceInput>

      <ReferenceInput source="technologyId" reference="technologies">
        <SelectInput
          label="technologia"
          validate={[required()]}
          createLabel="dodaj nowy"
          create={
            <CreateNewDialog
              label="nazwa technologii"
              reference="technologies"
            />
          }
        />
      </ReferenceInput>

      <ReferenceArrayInput source="industryIds" reference="industries">
        <SelectArrayInput
          label="branże"
          validate={[required()]}
          createLabel="dodaj nowy"
          create={
            <CreateNewDialog label="nazwa branży" reference="industries" />
          }
        />
      </ReferenceArrayInput>
    </>
  );
};
