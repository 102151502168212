import { doc, getDoc } from 'firebase/firestore';

import { db } from '../configs/firebase/config';

export const fetchData = async (collecionName: string, id: string) => {
  const dataRef = doc(db, collecionName, id);

  try {
    const dataDocs = await getDoc(dataRef);
    const data = dataDocs.data();

    return data;
  } catch (error) {
    console.error(`Error fetching ${collecionName}, id: ${id}:`, error);
    throw error;
  }
};
