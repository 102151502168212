import { required, TextInput, ReferenceArrayInput, SelectInput } from 'react-admin';

import { CategoryPropsType } from '../../../types/categories.types';

export const CategoryForm = (props: CategoryPropsType) => {
  const { isSubCategory } = props;

  return (
    <>
      {isSubCategory && (
        <ReferenceArrayInput
          source='parentId'
          reference='categories'
          validate={required()}
          link='show'
        >
          <SelectInput
            label='nazwa kategorii nadrzędnej'
            optionText='name'
            validate={required()}
          />
        </ReferenceArrayInput>
      )}
      <TextInput
        label='nazwa kategorii'
        source='name'
        validate={required()}
      />
    </>
  );
};
