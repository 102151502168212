import { MakeForm } from '../MakeForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const CreateMake = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='create'
      title='Dodaj producenta'
      redirect={props.redirect}
    >
      <MakeForm />
    </ActionController>
  );
};
