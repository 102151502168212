import { IncomeForm } from '../IncomeForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const CreateIncome = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='create'
      title='Dodaj wpływ'
      redirect={props.redirect}
    >
      <IncomeForm />
    </ActionController>
  );
};
