type HeaderLabelProps = {
  label: string;
  textAlign?: 'left' | 'center' | 'right';
  justifyContent?: 'flex-end' | 'flex-start' | 'center';
  alignItems?: 'flex-end' | 'flex-start' | 'center';
};

export const HeaderLabel = (props: HeaderLabelProps) => {
  const { label, ...rest } = props;
  const labelCopy = label.toUpperCase();
  return <b style={{ ...rest }}>{labelCopy}</b>;
};
