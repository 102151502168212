import { NumberInput, DateInput, required, TextInput } from 'react-admin';

export const OutcomeForm = () => {
  return (
    <>
      <TextInput
        source='name'
        label='nazwa'
        validate={required()}
      />

      <TextInput
        source='details'
        label='szczegóły'
        validate={required()}
      />

      <NumberInput
        source='amount'
        label='kwota'
        validate={required()}
      />

      <DateInput
        source='outcomeDate'
        label='data'
        validate={required()}
      />
    </>
  );
};
