import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BulkDeleteButton,
} from 'react-admin';

import { EmptyBox } from '../table/EmptyBox';
import { HeaderLabel } from '../table/HeaderLabel';

export const ModelList = () => {
  return (
    <List
      perPage={50}
      empty={<EmptyBox createLabel='Dodaj' hasCreate />}
      sort={{ field: 'createdate', order: 'DESC' }}
    >
      <Datagrid
        rowClick='edit'
        sx={{ textTransform: 'uppercase' }}
        bulkActionButtons={<BulkDeleteButton mutationMode='pessimistic' />}
      >
        <TextField source='name' label={<HeaderLabel label='nazwa' />} />

        <ReferenceField
          source='makeId'
          reference='makes'
          label={<HeaderLabel label='Powiązany producent' />}
        />
      </Datagrid>
    </List>
  );
};
