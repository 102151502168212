import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BulkDeleteButton,
  useGetList,
  RaRecord,
  FieldProps,
  useRecordContext,
} from 'react-admin';

import { EmptyBox } from '../table/EmptyBox';
import { HeaderLabel } from '../table/HeaderLabel';

const DisplayNameField = (props: FieldProps) => {
  const record = useRecordContext(props);
  const { data: tools, isLoading } = useGetList('tools');

  if (isLoading) {
    return <>...</>;
  }

  const toolName =
    tools?.find((tool: RaRecord) => tool.modelId === record?.modelId)
      .displayName || record.name;

  return <>{toolName}</>;
};

export const VariantList = () => {
  return (
    <List
      perPage={50}
      empty={<EmptyBox createLabel='Dodaj' hasCreate />}
      sort={{ field: 'createdate', order: 'DESC' }}
    >
      <Datagrid
        rowClick='edit'
        sx={{ textTransform: 'uppercase' }}
        bulkActionButtons={<BulkDeleteButton mutationMode='pessimistic' />}
      >
        <TextField source='name' label={<HeaderLabel label='nazwa' />} />

        <DisplayNameField
          source='modelId'
          label={<HeaderLabel label='narzędzie' />}
        />

        {/* <ReferenceArrayField
          label='Tools'
          source='includes'
          reference='tools'
        >
          <SingleFieldList>
            <ReferenceField
              source='name'
              reference='tools'
            >
              <ChipField
                source='name'
                label='nazwa'
              />
            </ReferenceField>
          </SingleFieldList>
        </ReferenceArrayField> */}
      </Datagrid>
    </List>
  );
};
