import { required, TextInput, ReferenceInput, SelectInput } from 'react-admin';

export const TodoForm = () => {
  return (
    <>
      <TextInput
        label='nazwa'
        source='name'
        validate={required()}
      />

      <TextInput
        label='opis'
        source='description'
        validate={required()}
      />

      <ReferenceInput
        source='responsibleUser'
        reference='users'
        label='odpowiedzialny'
      >
        <SelectInput
          name='responsibleUser'
          label='przypisano do'
          validate={[required()]}
        />
      </ReferenceInput>

      <TextInput
        label='status'
        source='status'
        validate={required()}
      />
    </>
  );
};
