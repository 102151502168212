import { gapi } from 'gapi-script';
import { initializeApp } from 'firebase/app';
import { combineDataProviders } from 'react-admin';
import { getFirestore } from '@firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { FirebaseAuthProvider, FirebaseDataProvider } from 'react-admin-firebase';

import { firebaseConfig } from '..';
// import { googleSheetsDataProvider } from '../../dataGoogleSheets';

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();

(() => {
  const start = () =>
    gapi.auth2.getAuthInstance({
      clientId: process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_ID,
      scope: 'email'
    });

  gapi.load('client:auth2', start);
})();

export const Providers = { google: new GoogleAuthProvider() };
export const authProvider = FirebaseAuthProvider(firebaseConfig, {});

// const resourcesMap = {
//   incomes: googleSheetsDataProvider('1UmGQPwPGwKHWgQL9kvze1KyrPkppwArEGpUwlrUqf_8'),
//   outcomes: googleSheetsDataProvider('1UmGQPwPGwKHWgQL9kvze1KyrPkppwArEGpUwlrUqf_8')
// };
// const fakeDataProvider = {
//   create: () => Promise.resolve(null),
//   delete: () => Promise.resolve(null),
//   deleteMany: () => Promise.resolve(null),
//   getList: () => Promise.resolve(null),
//   getMany: () => Promise.resolve(null),
//   getManyReference: () => Promise.resolve(null),
//   getOne: () => Promise.resolve(null),
//   update: () => Promise.resolve(null),
//   updateMany: () => Promise.resolve(null)
// };
// const googleDataProvider = new Proxy(fakeDataProvider, {
//   get: (_, name) => {
//     return (resource, params) => {
//       const resourceDataProvider = resourcesMap[resource];
//       if (!resourceDataProvider) {
//         throw new Error(`Invalid resource "${resource}"`);
//       }

//       if (!resourceDataProvider[name]) {
//         throw new Error(`Invalid action "${name}" for resource "${resource}"`);
//       }

//       return resourceDataProvider[name](resource, params);
//     };
//   }
// });

const dataProvider1 = FirebaseDataProvider(firebaseConfig, {});
// const dataProvider2 = googleDataProvider;

export const dataProvider = combineDataProviders((resource) => {
  // if (resource === 'incomes' || resource === 'outcomes') {
  //   return dataProvider2;
  // }
  return dataProvider1;
});
