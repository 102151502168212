import { FormEvent, useState } from "react";
import {
  useCreateSuggestionContext,
  useCreate,
  ReferenceArrayInput,
  required,
  SelectInput,
  useRefresh,
} from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";

import { CreateNewDialogPropsType } from "../../types/global.types";

export const CreateNewCategoryDialog = (props: CreateNewDialogPropsType) => {
  const { reference, label } = props;

  const [create] = useCreate();
  const [name, setName] = useState("");
  const [parentId, setParentId] = useState("");
  const { onCancel, onCreate } = useCreateSuggestionContext();
  const refresh = useRefresh();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      reference,
      { data: { name, parentId } },
      {
        onSuccess: (data) => {
          setName("");
          onCreate(data);
          refresh();
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <ReferenceArrayInput
            source="parentId"
            reference="categories"
            validate={required()}
          >
            <SelectInput
              label="nazwa kategorii nadrzędnej"
              onChange={({ target: { value } }) => setParentId(value)}
              validate={required()}
              sx={{ mr: 2 }}
            />
          </ReferenceArrayInput>

          <TextField
            label={label}
            value={name}
            onChange={({ target: { value } }) => setName(value)}
            autoFocus
            required
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
