import { App } from './App';

import ReactDOM from 'react-dom/client';
// import { loadGoogleApi } from './dataGoogleSheets';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// loadGoogleApi({
//   apiKey: process.env.REACT_APP_GOOGLE_SHEETS_API_KEY,
//   clientId: process.env.REACT_APP_GOOGLE_SHEETS_CLIENT_ID
// }).then(() => {
root.render(<App />);
// });
