import {
  NumberInput,
  DateInput,
  required,
  useGetList,
  SelectInput,
  minValue,
  TextInput,
  BooleanInput,
} from 'react-admin';

import { Loader } from '../../table/Loader';
import { HeaderLabel } from '../../table/HeaderLabel';
import FavoriteIcon from '@mui/icons-material/Favorite';

export const IncomeForm = () => {
  const { data: tools, isLoading: isLoadingTools } = useGetList('tools');
  const { data: invoices, isLoading: isLoadingInvoices } =
    useGetList('invoices');

  if (isLoadingTools || isLoadingInvoices) {
    return <Loader />;
  }

  return (
    <>
      <SelectInput
        label='narzędzie'
        source='toolId'
        optionText='displayName'
        choices={tools}
        validate={required()}
      />

      <NumberInput
        source='quantity'
        label='ilość'
        validate={minValue(1)}
        defaultValue={1}
      />

      <NumberInput source='amount' label='kwota' validate={required()} />

      <NumberInput
        source='provision'
        label='prowizja'
        validate={required()}
        defaultValue={0}
      />

      <DateInput source='incomeDate' label='data' validate={required()} />

      <TextInput source='details' label='opis' defaultValue='' />

      <SelectInput
        label='faktura'
        source='invoiceId'
        choices={invoices}
        defaultValue={null}
        optionText={(record) =>
          `${record.name} (${record.status} / ${record.date})`
        }
      />

      <BooleanInput
        source='paid'
        sx={{ justifyContent: 'center', alignItems: 'center' }}
        label={<HeaderLabel label='opłacone' />}
        options={{ checkedIcon: <FavoriteIcon /> }}
      />
    </>
  );
};
