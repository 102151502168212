import { TextInput, required, ReferenceInput, SelectInput } from 'react-admin';

export const SystemForm = () => {
  return (
    <>
      <TextInput
        label='nazwa'
        source='name'
        validate={required()}
      />

      <ReferenceInput
        source='makeId'
        reference='makes'
      >
        <SelectInput
          source='make'
          label='Powiązany producent'
          validate={required()}
        />
      </ReferenceInput>
    </>
  );
};
