import { VariantForm } from '../VariantForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const CreateVariant = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='create'
      title='Dodaj wariant'
      redirect={props.redirect}
    >
      <VariantForm />
    </ActionController>
  );
};
