import { useRecordContext } from 'react-admin';
import { Chip } from '@mui/material';
import { InvoiceDataType } from '../lists/InvoiceList';

export const InvoiceChip = ({
  source,
  invoiceData,
  customColors,
}: {
  source: string;
  invoiceData: InvoiceDataType[];
  customColors?: boolean;
}) => {
  const record = useRecordContext();
  const invoiceDetails = invoiceData.find(
    (type) => type.typeNumber === record[source]
  );

  if (!invoiceDetails) {
    return null;
  }

  let typeColor = 'default' as
    | 'default'
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'secondary'
    | 'primary';

  if (!customColors) {
    if (invoiceDetails.typeNumber === 1) {
      // SPRZEDAŻ
      typeColor = 'info';
    } else {
      // ZAKUP
      typeColor = 'error';
    }
  } else {
    if (invoiceDetails.typeNumber === 1) {
      // WYSTAWIONO
      typeColor = 'default';
    } else if (invoiceDetails.typeNumber === 2) {
      // OPŁACONO
      typeColor = 'primary';
    } else if (invoiceDetails.typeNumber === 3) {
      // DO OPŁACENIA
      typeColor = 'warning';
    } else {
      //PRZETERMINOWANE
      typeColor = 'error';
    }
  }

  return <Chip label={invoiceDetails.name} color={typeColor!} size='small' />;
};
