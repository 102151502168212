import { UserForm } from '../UserForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const EditUser = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='edit'
      title='Edytuj użytkownika'
      redirect={props.redirect}
    >
      <UserForm />
    </ActionController>
  );
};
