import { TextInput, required } from 'react-admin';

export const SimpleForm = () => {
  return (
    <TextInput
      label='nazwa'
      source='name'
      validate={[required()]}
    />
  );
};
