import {
  List,
  Datagrid,
  TextField,
  SelectField,
  useGetList,
  BulkDeleteButton,
} from 'react-admin';

import { Loader } from '../table/Loader';
import { EmptyBox } from '../table/EmptyBox';
import { HeaderLabel } from '../table/HeaderLabel';
import { CategoryPropsType } from '../../types/categories.types';

export const CategoryList = (props: CategoryPropsType) => {
  const { isSubCategory } = props;
  const { data: categories, isLoading } = useGetList('categories');

  if (isLoading) {
    return <Loader justifyContent='center' />;
  }

  return (
    <List
      perPage={50}
      empty={<EmptyBox hasCreate createLabel='Dodaj' />}
      sort={{ field: 'createdate', order: 'DESC' }}
    >
      <Datagrid
        rowClick='edit'
        sx={{ textTransform: 'uppercase' }}
        bulkActionButtons={<BulkDeleteButton mutationMode='pessimistic' />}
      >
        <TextField source='name' label={<HeaderLabel label='nazwa' />} />

        {isSubCategory && (
          <SelectField
            source='parentId'
            label={<HeaderLabel label='kategoria nadrzędna' />}
            choices={categories}
          />
        )}
      </Datagrid>
    </List>
  );
};
