import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BulkDeleteButton,
} from 'react-admin';

import { EmptyBox } from '../table/EmptyBox';
import { HeaderLabel } from '../table/HeaderLabel';

export const UserList = () => {
  return (
    <List
      perPage={50}
      empty={<EmptyBox createLabel='Dodaj' hasCreate />}
      sort={{ field: 'createdate', order: 'DESC' }}
    >
      <Datagrid
        rowClick='edit'
        sx={{ textTransform: 'uppercase' }}
        bulkActionButtons={<BulkDeleteButton mutationMode='pessimistic' />}
      >
        <TextField source='name' label={<HeaderLabel label='imię' />} />
        <TextField source='surname' label={<HeaderLabel label='nazwisko' />} />
        <TextField
          source='username'
          label={<HeaderLabel label='nazwa użytkownika' />}
        />
        <EmailField source='email' label={<HeaderLabel label='e-mail' />} />
        <TextField
          source='phone'
          label={<HeaderLabel label='numer telefonu' />}
        />
      </Datagrid>
    </List>
  );
};
