import { CreateButton } from 'react-admin';
import { Grid, Typography } from '@mui/material';

type EmptyBoxPropsType = {
  message?: string;
  hasCreate?: boolean;
  createLabel?: string;
  handleCreate?: () => void;
};

export const EmptyBox = (props: EmptyBoxPropsType) => {
  const { message = 'Brak danych', handleCreate, createLabel } = props;

  return (
    <Grid
      sx={{
        display: 'grid',
        placeContent: 'center',
        minWidth: '100%',
        minHeight: '500px'
      }}
    >
      <Typography
        variant='h4'
        paragraph
        sx={{ letterSpacing: '5px' }}
      >
        {message.toLowerCase()}
      </Typography>
      <CreateButton
        onClick={handleCreate}
        label={createLabel}
      />
    </Grid>
  );
};
