import { List, Datagrid, TextField, BulkDeleteButton } from 'react-admin';

import { EmptyBox } from '../table/EmptyBox';
import { HeaderLabel } from '../table/HeaderLabel';

export const OutcomeList = () => {
  return (
    <List
      perPage={50}
      empty={<EmptyBox createLabel='Dodaj' hasCreate />}
      sort={{ field: 'createdate', order: 'DESC' }}
    >
      <Datagrid
        rowClick='edit'
        sx={{ textTransform: 'uppercase' }}
        bulkActionButtons={<BulkDeleteButton mutationMode='pessimistic' />}
      >
        <TextField source='name' label={<HeaderLabel label='nazwa' />} />
        <TextField source='details' label={<HeaderLabel label='szczegóły' />} />
      </Datagrid>
    </List>
  );
};
