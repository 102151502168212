import { TechnologyForm } from '../TechnologyForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const EditTechnology = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='edit'
      title='Edytuj technologię'
      redirect={props.redirect}
    >
      <TechnologyForm />
    </ActionController>
  );
};
