import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  BulkDeleteButton,
  FunctionField,
  useGetList,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import { EmptyBox } from '../table/EmptyBox';
import { HeaderLabel } from '../table/HeaderLabel';
import { InvoiceChip } from '../customs/InvoiceChip';

export type InvoiceDataType = {
  name: string;
  typeNumber: number;
};

export const InvoiceList = () => {
  const { data: invoiceTypesData } = useGetList('invoiceTypes');
  const { data: invoiceStatusesData } = useGetList('invoiceStatuses');
  const invoiceTypes = invoiceTypesData as InvoiceDataType[];
  const invoiceStatuses = invoiceStatusesData as InvoiceDataType[];

  return (
    <List
      perPage={50}
      empty={<EmptyBox createLabel='Dodaj' hasCreate />}
      sort={{ field: 'createdate', order: 'DESC' }}
      filters={[
        <ReferenceInput source='invoiceType' reference='invoiceTypes' alwaysOn>
          <SelectInput optionValue='typeNumber' label='pokaż typ' />
        </ReferenceInput>,
        <ReferenceInput
          source='statusNumber'
          reference='invoiceStatuses'
          alwaysOn
        >
          <SelectInput optionValue='typeNumber' label='pokaż status' />
        </ReferenceInput>,
      ]}
    >
      <Datagrid
        rowClick='edit'
        sx={{ textTransform: 'uppercase' }}
        bulkActionButtons={<BulkDeleteButton mutationMode='pessimistic' />}
      >
        <TextField source='name' label={<HeaderLabel label='nazwa' />} />

        <FunctionField
          label={<HeaderLabel label='typ' />}
          render={() => {
            return (
              <InvoiceChip source='invoiceType' invoiceData={invoiceTypes} />
            );
          }}
        />

        <FunctionField
          label={<HeaderLabel label='status' />}
          render={() => {
            return (
              <InvoiceChip
                source='statusNumber'
                invoiceData={invoiceStatuses}
                customColors
              />
            );
          }}
        />

        <DateField
          source='date'
          label={<HeaderLabel label='data wystawienia' />}
        />

        <NumberField
          source='amount'
          label={<HeaderLabel label='kwota faktury' />}
        />
      </Datagrid>
    </List>
  );
};
