import { CircularProgress, Grid } from '@mui/material';

export const Loader = ({
  isActive = true,
  justifyContent = 'flex-end'
}: {
  isActive?: boolean;
  justifyContent?: 'flex-end' | 'center';
}) => {
  if (!isActive) {
    return <></>;
  }

  return (
    <Grid sx={{ placeContent: 'center', display: 'flex', justifyContent, minHeight: 150, alignItems: 'center' }}>
      <CircularProgress />
    </Grid>
  );
};
