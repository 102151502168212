import { FormEvent, useState } from 'react';
import {
  useCreateSuggestionContext,
  useCreate,
  ReferenceInput,
  SelectInput,
  required,
  useGetList,
  useRefresh,
  useNotify
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';

import { CreateNewDialogPropsType } from '../../types/global.types';
import { Loader } from '../table/Loader';

export const CreateNewModelDialog = (props: CreateNewDialogPropsType) => {
  const { reference, selectedMakeId } = props;

  const [create] = useCreate();
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const { data: models, isLoading } = useGetList('models');

  const [name, setName] = useState(filter || '');
  const [make, setMake] = useState(selectedMakeId || '');

  const refresh = useRefresh();
  const notify = useNotify();

  const ignoreCaseFilter = (model: { name: string }) => model.name.toLowerCase().trim() === name.toLowerCase().trim();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isModelUnique = models?.filter(ignoreCaseFilter).length === 0;

    if (!isModelUnique) {
      notify('Model o takiej nazwie już istnieje!', { type: 'error' });
      return;
    }

    create(
      reference,
      { data: { name, makeId: make } },
      {
        onSuccess: (data) => {
          onCreate(data);
          refresh();
          setName('');
          setMake('');
          notify('Model został dodany', { type: 'success' });
        }
      }
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Dialog
      open
      onClose={onCancel}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <ReferenceInput
            source='name'
            reference='models'
            validate={required()}
          >
            <TextField
              label='nazwa'
              value={name}
              onChange={({ target: { value } }) => setName(value)}
              autoFocus
              required
              fullWidth
            />
          </ReferenceInput>

          <ReferenceInput
            source='makeId'
            reference='makes'
          >
            <SelectInput
              source='makeId'
              disabled={!!selectedMakeId}
              label='Powiązany producent'
              validate={required()}
              value={make}
              defaultValue={selectedMakeId}
              fullWidth
              onChange={({ target: { value } }) => setMake(value)}
            />
          </ReferenceInput>
        </DialogContent>
        <DialogActions>
          <Button type='submit'>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
