import {
  List,
  Datagrid,
  NumberField,
  ReferenceField,
  DateField,
  useGetList,
  Toolbar,
  BooleanField,
  TextInput,
  BulkDeleteButton,
  FunctionField,
  RaRecord,
  CloneButton,
} from 'react-admin';

import { EmptyBox } from '../table/EmptyBox';
import { currencyOptions } from '../../utils';
import { HeaderLabel } from '../table/HeaderLabel';
import { Loader } from '../table/Loader';
import { Box, Card, Typography } from '@mui/material';

const TotalSum = ({ amount, quantity = 1, provision, paid }: RaRecord) => {
  let sumValue = amount * quantity - provision;
  let sumToFixed = sumValue.toFixed(2);
  let formattedSum = sumToFixed.replace('.', ',');

  let value = '0';

  if (!paid) {
    return <>0 zł</>;
  }

  if (quantity <= 1) {
    value = formattedSum;
  } else {
    sumValue = amount * quantity - provision;
    sumToFixed = sumValue.toFixed(2);
    formattedSum = sumToFixed.replace('.', ',');
    value = formattedSum;
  }

  return <>{value} zł</>;
};

const handleCustomSx = (record: RaRecord, index: number) => {
  if (!record.paid) {
    return {
      backgroundColor: '#fff5f5',
    };
  }

  return {};
};

export const IncomeList = () => {
  const { data: incomes, isLoading } = useGetList('incomes', {
    pagination: { page: 1, perPage: 150 },
    sort: { field: 'createdate', order: 'ASC' },
  });

  const incomeReducedSum = incomes
    ?.reduce((acc: number, curr) => {
      if (!curr.paid) return acc;

      const totalSum = curr.amount * curr.quantity - curr.provision;
      const result = acc + totalSum;

      return result || 0;
    }, 0)
    .toFixed(2);

  if (isLoading) {
    return <Loader justifyContent='center' />;
  }

  return (
    <>
      <List
        perPage={50}
        empty={<EmptyBox createLabel='Dodaj' hasCreate />}
        filters={[<TextInput label='nazwa' source='displayName' alwaysOn />]}
        sort={{ field: 'createdate', order: 'DESC' }}
        sx={{
          '& .RaDatagrid-rowCell:nth-child(3) ': {
            textAlign: 'center',
          },
          '& .RaDatagrid-rowCell:nth-child(7) ': {
            '& span': {
              maxWidth: '160px',
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          },
        }}
      >
        <Datagrid
          rowClick='edit'
          sx={{ textTransform: 'uppercase' }}
          bulkActionButtons={<BulkDeleteButton mutationMode='pessimistic' />}
          rowSx={handleCustomSx}
        >
          <ReferenceField
            source='toolId'
            reference='tools'
            label={<HeaderLabel label='nazwa' />}
          />

          <BooleanField
            source='paid'
            label={<HeaderLabel label='opłacone' />}
          />

          <NumberField
            source='quantity'
            label={<HeaderLabel label='ilość' />}
          />

          <NumberField
            source='amount'
            label={<HeaderLabel label='kwota' />}
            options={currencyOptions}
          />

          <NumberField
            source='provision'
            label={<HeaderLabel label='prowizja' />}
            options={currencyOptions}
          />

          <ReferenceField
            source='invoiceId'
            reference='invoices'
            label={<HeaderLabel label='faktura' />}
          />

          <FunctionField
            label={<HeaderLabel label='total' />}
            render={TotalSum}
            textAlign='right'
          />

          <DateField
            textAlign='right'
            source='incomeDate'
            label={<HeaderLabel label='data' textAlign='right' />}
          />
          <CloneButton />
        </Datagrid>
      </List>

      <Card>
        <Toolbar>
          <Box width='100%' display='flex' justifyContent='flex-end'>
            <Typography fontWeight='bold'>{incomeReducedSum}</Typography>
          </Box>
        </Toolbar>
      </Card>
    </>
  );
};
