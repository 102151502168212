import { TodoForm } from '../TodoForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const CreateTodo = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='create'
      title='Dodaj TODO'
      redirect={props.redirect}
    >
      <TodoForm />
    </ActionController>
  );
};
