import { VariantForm } from '../VariantForm';
import { ActionController } from '../../ActionController';
import { ActionControllerPropsType } from '../../../../types/global.types';

export const EditVariant = (props: ActionControllerPropsType) => {
  return (
    <ActionController
      action='edit'
      title='Edytuj wariant'
      redirect={props.redirect}
    >
      <VariantForm />
    </ActionController>
  );
};
